import React from "react";
import { Box, Divider, Grid } from "@mui/material";

export default function CRSPolicy() {
  return (
    <div className="terms-and-condition">
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-main-header-text">
              CORPORATE SOCIAL RESPONSIBILITY POLICY
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">1. PREFACE</div>
            <div className="privacy-policy-normal-text">
              CHANDUKAKA SARAF JEWELS PVT LTD ("Company") has always placed an
              extreme value on social responsibility, and as a result, the
              Company frequently promotes ethical initiatives. The Company makes
              a consistent effort to support issues related to society as a good
              corporate citizen. The Company believes that in addition, for an
              organization to be successful, it must uphold the highest
              standards of corporate behavior toward its society in which it
              operates. The Company's ambition for excellence in the overall
              performance of its company is the driving force behind its
              Corporate Social Responsibility Policy (the "Policy"), that has
              its foundation in the Company's core values of quality,
              reliability, and trust and is guided by international standards
              and best practices. This Policy has been framed in compliance with
              the requirements of Section 135 of The Companies Act, 2013 and the
              Companies (Corporate Social Responsibility Policy) Rules, 2014.
              The Board of the Company developed this Policy in accordance with
              the Corporate Social Responsibility Committee's suggestion.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              2. CORPORATE SOCIAL RESPONSIBILITY PHILOSOPHY
            </div>
            <div className="privacy-policy-normal-text">
              This Policy indicates the Company's philosophy as an Indian
              corporate citizen. It also establishes the procedures and criteria
              for carrying out community welfare and sustainable development
              projects in the closest area of the company's operations as well
              as in the neighboring cities.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              3. CORPORATE SOCIAL RESPONSIBILITY VISION & MISSION
            </div>
            <div className="privacy-policy-normal-text">
              <p>
                {" "}
                3.1 To actively employ environmentally conscious strategies to
                support the community's social, economic, and environmental
                development with the objective to add value for the country;
              </p>
              <p>
                {" "}
                3.2 To ensure the community's socioeconomic growth through
                different need based and participatory initiatives that serve
                the requirements of the underprivileged and impoverished
                segments of society with the goal of supporting them in becoming
                independent and creating greater opportunities for themselves;
              </p>
              <p>
                3.3 Develop strategies that are simultaneously appropriate and
                effective for communicating with everyone engaged;
              </p>
              <p>
                {" "}
                3.4 Consult community members to identify objectives for growth
                that can be both achievable and socially appropriate.
              </p>
              <p>
                3.5 Establish alliances with established organizations such as
                foundations, trusts, and non-profit organizations;
              </p>
              <p>
                3.6 Ensure efficient use of energy and environment friendly
                technologies.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">4. DEFINITIONS</div>
            <div className="privacy-policy-normal-text">
              <p> 4.1 ‘Act’ means the Companies Act, 2013.</p>
              <p> 4.2 ‘Board’ means the Board of Directors of the Company.</p>
              <p> 4.3 ‘Company’ means Chandukaka Saraf Jewels Pvt. Ltd.</p>
              <p>
                {" "}
                4.4 ‘CSR Committee or Committee’ means the Corporate Social
                Responsibility Committee of the Board constituted pursuant to
                Section 135 of the Act.
              </p>
              <p>
                {" "}
                4.5 ‘CSR or Corporate Social Responsibility’ means and includes
                but is not limited to:
                <p>
                  i) Projects or programs relating to activities specified in
                  Schedule VII of the Act; or
                </p>
                <p>
                  ii) Projects or programs relating to activities undertaken by
                  the Company in pursuance of the recommendations of the CSR
                  Committee and approved by the Board as per this Policy.
                </p>
              </p>
              <p>
                {" "}
                4.6 "Net Profit" refers to the company's net profit as stated in
                its financial statement, which has been prepared in accordance
                with the applicable law and regulations. It does not, however,
                include the following:
                <p>
                  i. ‘any profit arising from any overseas branch or branches of
                  the Company, whether operated as a separate company or
                  otherwise, and
                </p>
                <p>
                  ii. any dividend received from other companies in India, which
                  are covered under and complying with the provisions of Section
                  135 of the Act.
                </p>
              </p>
              <p>
                {" "}
                4.7 ‘Rules’ means the Companies (Corporate Social Responsibility
                Policy) Rules, 2014 or as may be amended from time to time.
                Words and expressions used in this Policy and not defined herein
                but defined under the Act or Rules shall have the meanings
                respectively assigned to them therein.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              5. CONSTITUTION OF CSR COMMITTEE AND ITS FUNCTIONS
            </div>
            <div className="privacy-policy-normal-text">
              In terms of Section 135 of the Act, the Board of the Company
              constituted a CSR Committee, headed by an independent director
              inter alia, to carry out the following functions:
              <p>
                (a) to formulate and recommend to the Board, a CSR Policy,
                indicating activities to be undertaken as specified in the
                prescribed Schedule;
              </p>
              <p>
                (b) to recommend the amount of expenditure to be incurred on the
                activities referred to in clause (a); and
              </p>
              <p>(c) to monitor the CSR Policy from time to time.</p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              6. CSR FUNDS / BUDGET
            </div>
            <div className="privacy-policy-normal-text">
              <p>
                {" "}
                The following amounts shall be allocated by the company to its
                yearly CSR funds or budget for the purpose of carrying out CSR
                activities:
              </p>
              <p>
                i. 2% of the average Net Profits of the Company made during the
                three immediately preceding financial years, as prescribed under
                the Act and the Rules, or such higher percentage as may be
                prescribed by the Central Government from time to time. The
                Average Net Profit shall be calculated in accordance with the
                provisions of Section 198 of the Act;
              </p>
              <p>ii. Any income arising therefrom; and</p>
              <p>
                iii. Surplus arising out of CSR activities carried out by the
                Company.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">7. CSR ACTIVITIES</div>
            <div className="privacy-policy-normal-text">
              The CSR activities that the Company will pursue will be in line
              with its stated Vision and Mission.
              <p>
                7.1 CSR programs or projects to be undertaken by the Company in
                terms of this Policy, shall relate to one or more activities
                listed in Schedule VII of the Act at present or as may be
                amended from time to time.{" "}
              </p>
              <b>
                Presently Schedule VII of the Act covers following activities:
              </b>
              <p>
                i. eradicating hunger, poverty and malnutrition, promoting
                healthcare including preventive health care and sanitation
                including contribution to the Swach Bharat Kosh set up by the
                Central Government for the promotion of sanitation and making
                available safe drinking water;
              </p>
              <p>
                ii. promoting education, including special education and
                employment enhancing vocation skills especially among children,
                women, elderly, and the differently abled and livelihood
                enhancement projects;
              </p>
              <p>
                iii. promoting gender equality, empowering women, setting up
                homes and hostels for women and orphans; setting up old age
                homes, day care centres and such other facilities for senior
                citizens and measures for reducing inequalities faced by
                socially and economically backward groups;
              </p>
              <p>
                iv. ensuring environmental sustainability, ecological balance,
                protection of flora and fauna, animal welfare, agroforestry,
                conservation of natural resources and maintaining quality of
                soil, air and water including contribution to the clean Ganga
                Fund set up by Central Government for rejuvenation of river
                Ganga ;
              </p>
              <p>
                v. protection of national heritage, art and culture including
                restoration of buildings and sites of historical importance and
                works of art; setting up public libraries, promotion and
                development of traditional arts and handicrafts;
              </p>
              <p>
                vi. measures for the benefit of armed forces veterans, war
                widows and their dependents;
              </p>
              <p>
                vii. training to promote rural sports, nationally recognised
                sports, Paralympic sports and Olympic sports;
              </p>
              <p>
                viii. contribution to the Prime Minister's National Relief Fund
                or any other fund set up by the Central Government for
                socioeconomic development and relief and welfare of the
                Scheduled Castes, the Scheduled Tribes, other backward classes,
                minorities and women;
              </p>
              <p>
                ix. contributions or funds provided to technology incubators
                located within academic institutions which are approved by the
                Central Government;
              </p>
              <p>x. rural development projects;</p>
              <p>xi. Slum Area Development.</p>
              <p>
                {" "}
                7.2 CSR expenditure shall include all expenditure, including
                contribut ions to the corpus, for projects or programs relating
                to CSR activiti es approved by the Board based on the
                recommendations of th e CSR Committee, but shall not include any
                expenditure on any activity that is not in conformity with or in
                line with the activities d efined in Schedule VII of the Act.{" "}
              </p>
              <p>
                7.3 The allocated CSR funds / budget will be utilized for CSR
                activities undertaken within India, which are not –
                <p>
                  i. exclusively for the benefit of employees of the Company or
                  their family members; and/or
                </p>
                <p>
                  ii. undertaken in pursuance of normal course of business of
                  the Company.
                </p>
              </p>
              <p>
                7.4 Contribution of any amount, directly or indirectly, to any
                political party under Section 182 of the Act, shall not be
                considered as CSR activity.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              8. MODE OF IMPLEMENTATION
            </div>
            <div className="privacy-policy-normal-text">
              <p>
                8.1 CSR programs or projects under this Policy, will be
                implemented either –<p>i. directly by the Company; or</p>
                <p>
                  ii. through a Registered Trust or a Registered Society or
                  Section 8 Company (i.e. Company registered under Section 8 of
                  the Act), established by the Company or its holding or
                  subsidiary or associate company; or
                </p>
                <p>
                  iii. through any other Registered Trust or Registered Society
                  or Section 8 Company, not being established by the Company or
                  its holding or subsidiary or associate company: Provided that
                  it shall have an established track record of at least three
                  years in undertaking similar programs or projects; or
                </p>
                <p>
                  iv. in collaboration with other companies: Provided that CSR
                  Committees of respective companies are in a position to report
                  separately on such projects or programs in accordance with the
                  Rules; or
                </p>
                <p>v. combination of one or more of the above.</p>
              </p>
              <p>
                8.2 In case one or more CSR projects or programs are undertaken
                in collaboration with any other company, clear demarcation with
                respect to responsibility, contribution, implementation,
                monitoring etc. of each collaborator shall be established.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">9. IMPLEMENTATION</div>
            <div className="privacy-policy-normal-text">
              <p>
                9.1 The Committee shall from time to time decide / recommend to
                the Board on CSR activities to be undertaken, the geographical
                areas covered, mode and schedule of implementation, the amount
                to be spent on various projects.
              </p>
              <p>
                9.2 The Committee shall have full discretion to specify or
                modify the modalities of execution of CSR projects or programs
                and to determine implementation schedule.
              </p>
              <p>
                9.3 The time period for implementation of a particular CSR
                project or program or activity shall depend on, amongst other
                things, its nature, extent of coverage, intended impact of the
                program etc.
              </p>
              <p>
                9.4 All CSR projects or programs under this Policy shall be
                undertaken in India only, with due consideration and preference
                to local areas around areas of operations of the Company.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">
              10. MONITORING PROCESS
            </div>
            <div className="privacy-policy-normal-text">
              <p>
                10.1 In order to ensure effective implementation of CSR projects
                or programs, the Committee while approving projects or programs
                to be undertaken by the Company pursuant to this Policy during a
                financial year, shall decide the implementation schedule and
                frame suitable project-specific monitoring mechanism.
              </p>
              <p>
                10.2 The Committee shall hold at least two meetings during a
                financial year, inter-alia, to monitor the progress of on-going
                CSR projects or programs and report the same to the Board.
              </p>
              <p>
                10.3 The Committee shall on completion of every Financial Year,
                submit to the Board, a report on details of the CSR spent during
                the Financial Year and specify the reasons for the actual
                expenditure being less than the prescribed limits.
              </p>
              <p>
                10.4 The Committee may recommend changes / modifications to be
                carried out in the Policy to the Board from time to time.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">11. ROLE OF BOARD</div>
            <div className="privacy-policy-normal-text">
              <p>The role and responsibilities of the Board shall include:</p>
              <p>
                i. Approval of this Policy after taking into account
                recommendations of the CSR Committee.
              </p>
              <p>
                ii. The Directors’ Report of the Company shall include an annual
                report on CSR containing the particulars prescribed by the Act
                or the Rules and disclose contents of the Policy in its report.
              </p>
              <p>
                iii. Ensure that the Policy shall be displayed on the Company’s
                website www.csjewellerys.com.
              </p>
              <p>
                iv. Ensure that the CSR activities are undertaken and executed
                by the Company as per this Policy and the applicable laws.
              </p>
              <p>
                v. Ensure that the Company spends, in every financial year, at
                least 2% of the average net profits of the Company made during
                the three immediately preceding financial years, in pursuance of
                this Policy.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">12. GENERAL</div>
            <div className="privacy-policy-normal-text">
              <p>
                12.1 The activities undertaken by the Company in pursuance of
                its normal course of business shall not be considered as CSR
                activities.
              </p>
              <p>
                12.2 The interpretation of the Chairman of CSR Committee on the
                provisions of this Policy shall be final.
              </p>
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
      <Box className="privacy-policy-container" sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
          <Grid item xs={10} md={4}>
            <div className="privacy-policy-header-text">13. AMENDMENT</div>
            <div className="privacy-policy-normal-text">
              The Board can alter or modify this Policy according to necessity
              on the recommendation of the CSR Committee, according to the
              restrictions of the Act and the Rules.
            </div>
            <Divider className="divider-privacy-policy" />
          </Grid>
          <Grid item xs={1} md={4}>
            <div className="empty-container-section">xs=6 md=4</div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
